import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link } from "gatsby"
import * as React from "react"
import { useEffect } from "react"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import highlightCode from "../components/utils/HighlightCode"

// props
interface Props {
  data: {
    apiGuide: {
      description: string
      name: string
      path: string
      title: string
      type: string
      content: any
      projects: Array<string>
      githubUrl: string
    }
    projects: {
      nodes: Array<{
        title: string
        id: string
        slug: string
      }>
    }
  }
}

// markup
const GuideTemplate = (props: Props) => {
  useEffect(() => {
    highlightCode()
  })
  const projects = props.data.projects.nodes.filter(
    node => props.data.apiGuide.projects.indexOf(node.slug) > -1
  )
  let categoryString = "Getting Started"
  if (props.data.apiGuide.type === "topicalGuides") {
    categoryString = "Topical"
  } else if (props.data.apiGuide.type === "tutorials") {
    categoryString = "Tutorial"
  }
  return (
    <Layout
      seo={{
        title: `${categoryString} | ${props.data.apiGuide.title}`,
        description: props.data.apiGuide.description,
        hasNoSpring: true,
      }}
      className="guide"
    >
      <div className="container py-6">
        <div className="pb-5">
          <Link to={"/guides"} className="button is-white is-white-spring is-button-back">
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            All guides
          </Link>
        </div>
        <div className="columns is-space-between">
          <div className="column is-9">
            <div className="ascii-doc">
              <h1 className="title">{props.data.apiGuide.title}</h1>
              <HTMLContent content={props.data.apiGuide.content}></HTMLContent>
            </div>
          </div>
          <div className="column is-3">
            <div className="has-background-light px-5 py-5 mb-4">
              <h2 className="is-size-4 has-text-weight-bold">Get the Code</h2>
              <div className="container pt-3 has-text-weight-bold">
                <a href={props.data.apiGuide.githubUrl}>
                  <FontAwesomeIcon
                    className="ml-1 mr-3 is-size-5"
                    icon={faGithub}
                    height="20"
                  />
                  Go To Repo
                </a>
              </div>
            </div>
            {projects.length > 0 && (
              <div className="has-background-light px-5 py-5 mb-4">
                <h2 className="is-size-4 has-text-weight-bold">Projects</h2>
                <div className="container pt-3 has-text-weight-bold">
                  {projects.map((project, i) => (
                    <Link key={i} to={`/projects/${project.slug}`}>
                      {project.title}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GuideTemplate

// graphQL queries
export const pageQuery = graphql`
  query GuideTemplate($id: String!) {
    apiGuide(id: { eq: $id }) {
      content
      title
      description
      type
      path
      name
      projects
      githubUrl
    }
    projects: allContentfulProject(sort: { order: ASC, fields: order }) {
      nodes {
        id
        title
        slug
      }
    }
  }
`
